<template>
  <div class="container">
    <div class="input_div">
      <div class="buttons_div">
        <!--        <div>-->
        <!--          <el-button type="primary" :size="size" @click="openManifestDialog()" v-if="checkPer(['shManifestDraft:add'])">-->
        <!--            新建舱单-->
        <!--          </el-button>-->
        <!--          <el-button type="primary" plain :size="size" @click="exportDialogVisible1 = true">列表模板导入/下载-->
        <!--          </el-button>-->
        <!--          <el-button type="primary" plain :size="size" @click="exportDialogVisible2 = true">单票模板导入/下载-->
        <!--          </el-button>-->
        <!--          <el-button type="primary" :size="size" plain @click="exportHistoryExcel()"-->
        <!--                     :loading="exportLoading">导出记录-->
        <!--          </el-button>-->
        <!--        </div>-->
        <div>

          <el-dropdown v-if="checkPer(['shManifest:agent_delete'])" @command="setReceiptStatus">
            <el-button type="warning" :size="size" :loading="deleteLoading">
              刷新回执<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">重新生成报文</el-dropdown-item>
              <el-dropdown-item command="1">重新获取海关回执</el-dropdown-item>
              <el-dropdown-item command="2">发送成功</el-dropdown-item>
              <el-dropdown-item command="3">修改成功</el-dropdown-item>
              <el-dropdown-item command="-2">删除成功</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
              type="primary"
              :size="size"
              @click="sendManifest"
              :loading="sendLoading"
          >
            发送原始报文
          </el-button>

          <!--        <el-button type="warning" :size="size" plain @click="agentSync()" v-if="type===2">刷新回执</el-button>-->
          <!--        <el-button type="success" :size="size" plain @click="agentDeclare()" v-if="type===2">船代申报</el-button>-->

          <!--          <el-button type="danger" :size="size" plain style="margin-right:1rem;" @click="toDelete(0)"-->
          <!--                     :loading="deleteLoading"-->
          <!--          >删除</el-button>-->
          <!--          <el-button type="primary" :size="size" @click="toModify()"-->
          <!--                     icon="el-icon-warning-outline"-->
          <!--                     :loading="deleteLoading"-->
          <!--                     v-if="checkPer(['shManifest:agent_update'])"-->
          <!--          >发送修改报文-->
          <!--          </el-button>-->
          <!--          <el-button type="warning" :size="size"-->
          <!--                     icon="el-icon-warning-outline"-->
          <!--                     :loading="deleteLoading"-->
          <!--                     v-if="checkPer(['shManifest:agent_reissue'])"-->
          <!--                     @click="operator('agent_reissue',scope.row)"-->
          <!--          >发送重发报文-->
          <!--          </el-button>-->
          <!--          <el-button type="warning" :size="size" @click="toModify()"-->
          <!--                     icon="el-icon-warning-outline"-->
          <!--                     :loading="deleteLoading"-->
          <!--                     v-if="checkPer(['shManifest:agent_direct_reissue'])"-->
          <!--          >直接重发（不修改报文）-->
          <!--          </el-button>-->

          <!--          <el-button type="warning" :size="size" style="margin-right:1rem;" @click="counterDelete"-->
          <!--                     icon="el-icon-warning-outline"-->
          <!--                     :loading="deleteLoading"-->
          <!--          >柜台删单-->
          <!--          </el-button>-->
          <el-button
              type="danger"
              :size="size"
              style="margin-right: 1rem"
              @click="toDelete(1)"
              icon="el-icon-warning-outline"
              :loading="deleteLoading"
              v-if="checkPer(['shManifest:agent_delete'])"
          >
            发送删除报文
          </el-button>
          <el-button
              type="primary"
              :size="size"
              plain
              @click="exportExcel()"
              :loading="exportLoading"
          >
            导出舱单文件
          </el-button>
        </div>
      </div>
      <div style="display: flex">
        <!--        <div style="margin-right: 10px">-->
        <!--          <el-date-picker-->
        <!--              :size="size"-->
        <!--              v-model="dateRange"-->
        <!--              type="daterange"-->
        <!--              align="right"-->
        <!--              unlink-panels-->
        <!--              range-separator="至"-->
        <!--              start-placeholder="开始日期"-->
        <!--              end-placeholder="结束日期"-->
        <!--              value-format="yyyy-MM-dd"-->
        <!--              @change="setDate"-->
        <!--              :picker-options="pickerOptions">-->
        <!--          </el-date-picker>-->
        <!--        </div>-->
        <!--        <el-select class="el_select"-->
        <!--                   :size="size"-->
        <!--                   v-model="search.billStatus"-->
        <!--                   placeholder="选择发送结果"-->
        <!--        >-->
        <!--          <el-option-->
        <!--              v-for="item in billStatus"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--          ></el-option>-->
        <!--        </el-select>-->
        <el-select
            class="el_select"
            :size="size"
            v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
            @blur="blur"
            :filter-method="filterCompany"
            filterable
            clearable
            remote
            :remote-method="getAllCompany"
            v-model.trim="search.showCompanyId"
            @change="getCompanyUsers"
            placeholder="选择企业"
        >
          <el-option
              v-for="item in companysOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-select
            class="el_select"
            :size="size"
            filterable
            clearable
            v-model.trim="search.showUserId"
            placeholder="选择用户"
        >
          <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-select
            class="el_select"
            :size="size"
            v-model="search.webtype"
            placeholder="请选择船代"
            clearable
        >
          <el-option
              v-for="item in shipAgent"
              :key="item.value"
              :label="item.label"
              :value="item.label + '|' + item.value"
          ></el-option>
        </el-select>
        <el-input
            class="inline-input"
            v-model.trim="search.subject"
            :size="size"
            @keyup.enter.native="go2Search"
            placeholder="输入主题搜索"
            clearable
        ></el-input>
        <el-select
            class="inline-input"
            v-model="search.vesselId"
            :size="size"
            remote
            filterable
            :remote-method="getVesselByKeyword"
            @keyup.enter.native="go2Search"
            placeholder="请输入船名/航次"
            clearable
        >
          <el-option
              v-for="item in vesselOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
          ></el-option>
        </el-select>
        <el-input
            class="inline-input"
            v-model.trim="search.ctnNo"
            :size="size"
            @keyup.enter.native="go2Search"
            placeholder="请输入箱号"
            clearable
        ></el-input>
        <div class="bill_search_div">
          <el-input
              class="inline-input"
              v-model.trim="search.billno"
              :size="size"
              @keyup.enter.native="go2Search"
              @input="formatBills"
              placeholder="请输入提单号"
              clearable
          ></el-input>
          <p class="multi_bill_div" @click="showMultiBillDialog">
            多票
          </p>
        </div>
        <el-button type="primary" :size="size" @click="go2Search">
          查询
        </el-button>
        <el-button
            type="primary"
            :size="size"
            @click="changCompany"
            style="margin-left: auto"
        >
          更改归属公司
        </el-button>
      </div>
    </div>
    <el-dialog
        title="更改归属公司"
        :visible.sync="dialogFormVisible"
        width="30%"
        center
    >
      <div v-if="selectedItem" class="spaced">
        要将提单号为
        <strong class="blue-text">{{ selectedItem.billno }}</strong>
      </div>
      <div v-if="selectedItem" class="spaced">
        公司为
        <strong class="blue-text">
          {{ selectedItem.companyname }}
        </strong>
      </div>
      <el-form :model="form">
        <el-form-item class="form-inline">
          <div class="flex-container">
            <span class="label">更改归属为</span>
            <el-select
                class="el_select"
                style="width: 250px"
                :size="size"
                v-el-select-loadmore:rangeNumber="
                                loadMore(rangeNumber)
                            "
                @blur="blur"
                :filter-method="filterCompany"
                filterable
                clearable
                remote
                :remote-method="getAllCompany"
                v-model.trim="form.companyId"
                placeholder="选择企业"
            >
              <el-option
                  v-for="item in companysOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sumbitCompany">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="列表模板导入/下载"
        :visible.sync="exportDialogVisible1"
        width="30%"
        center
        :modal="false"
    >
      <div class="export_module">
        <p>可先下载模板按照格式填写后上传</p>
        <!--        <el-image :src="demoImg2[0]"-->
        <!--                  fit="cover"-->
        <!--                  :preview-src-list="demoImg2"-->
        <!--                  style="width: 100px; height: 100px">-->
        <!--        </el-image>-->
      </div>
      <el-upload
          style="margin: 2rem auto; text-align: center"
          :file-list="fileList1"
          :auto-upload="false"
          class="upload-demo"
          drag
          action=""
          :on-change="fileUpload1"
          multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
                <el-button @click="exportDialogVisible1 = false">
                    取 消
                </el-button>
                <el-button type="primary" @click="toDownload1()">
                    下载模板
                </el-button>
            </span>
    </el-dialog>
    <el-dialog
        title="单票模板导入/下载"
        :visible.sync="exportDialogVisible2"
        width="30%"
        center
    >
      <div class="export_module">
        <p>可先下载模板按照格式填写后上传</p>
        <!--        <el-image :src="demoImg2[0]"-->
        <!--                  fit="cover"-->
        <!--                  :preview-src-list="demoImg2"-->
        <!--                  style="width: 100px; height: 100px">-->
        <!--        </el-image>-->
      </div>
      <el-upload
          style="margin: 2rem auto; text-align: center"
          :file-list="fileList2"
          :auto-upload="false"
          class="upload-demo"
          drag
          action=""
          :on-change="fileUpload2"
          multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
                <el-button @click="exportDialogVisible2 = false">
                    取 消
                </el-button>
                <el-button type="primary" @click="toDownload2()">
                    下载模板
                </el-button>
            </span>
    </el-dialog>
    <AgentDeleteManifestDialog
        ref="AgentDeleteManifestDialog"
        @refresh="go2Search"
        @deleteError="showErrorList"
    ></AgentDeleteManifestDialog>
    <ShowManifestErrorDialog
        ref="ShowManifestErrorDialog"
    ></ShowManifestErrorDialog>
    <MultiBillNoDialog
        ref="MultiBillNoDialog"
        @searchBills="searchBills"
    ></MultiBillNoDialog>
  </div>
</template>

<script>
import AgentDeleteManifestDialog from "@/views/ShanghaiManifest/AllManifest/AgentDeleteMainfestDialog.vue";
import exportModuleImg1 from "@/assets/img/shanghaiManifest/sh_manifest_demo_1.png";
import exportModuleImg2 from "@/assets/img/shanghaiManifest/sh_manifest_demo_2.png";
import {
  agentSync, billIsDelete, billIsSend, billIsUpdate,
  downloadExcelModule1,
  downloadExcelModule2, eleDelete,
  exportManifestHistoryExcel,
  exportShipExcel,
  getShipAgentList,
  importExcelModule1,
  importExcelModule2, resetBillSendStatus, resetIsSend,
} from "@/api/shanghaiManifest";
import moment from "moment";
import {
  sendHyManifestShHead,
  changCompanyResquest,
} from "@/api/shanghaiManifest";
import {getUserAccountInfo} from "@/utils";
import {getAllCompany, getAllCompanyStaffById} from "@/api/company";
import {List} from "@/utils/echarts/echarts";
import {getVesselByKeyword} from "@/api/ship";
import MultiBillNoDialog from "@/views/ShanghaiManifest/components/MultiBillNoDialog.vue";
import ShowManifestErrorDialog from "@/views/ShanghaiManifest/components/ShowManifestErrorDialog.vue";

export default {
  name: "AllManifestSearchBar",
  components: {
    MultiBillNoDialog,
    ShowManifestErrorDialog,
    AgentDeleteManifestDialog,
  },
  props: {
    multipleSelection: {
      //选中的信息
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deleteLoading: false,
      sendLoading: false,
      exportLoading: false,
      size: "medium",
      shipAgent: [], //船代
      userList: [], //企业用户可以筛选查看其他同事的单子
      companyList: [], //系统操作员可查看其他企业
      companysOptions: [], //系统操作员可查看其他企业
      vesselOptions: [],
      billStatus: [
        {
          label: "全部状态",
          value: 0,
        },
        {
          label: "回执成功",
          value: 1,
        },
        {
          label: "回执失败",
          value: 2,
        },
      ], //舱单状态
      demoImg1: [exportModuleImg1],
      demoImg2: [exportModuleImg2],
      fileList1: [],
      fileList2: [],
      selectedItem: null,
      dialogFormVisible: false,
      exportDialogVisible1: false, //列表模板下载
      exportDialogVisible2: false, //海管家模板下载
      search: {
        showUserId: "", //筛选相应用户
        showCompanyId: "", //筛选相应企业
        billStatus: 0, //发送的舱单状态
        billno: "", //搜索的提单号
        vesselId: "", //搜索的船期id
        vesselname: "", //搜索的船名
        voyageno: "", //搜索的航次
        sort: "updatetime_desc",
        startDate: "",
        endDate: "",
        subject: "", //客户主题
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                  start.getTime() - 3600 * 1000 * 24 * 7,
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                  start.getTime() - 3600 * 1000 * 24 * 30,
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                  start.getTime() - 3600 * 1000 * 24 * 90,
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dateRange: "", //日期时间范围
      companyCurrPage: 1,
      companyTotalPage: 0,
      companyPageSize: 10,
      rangeNumber: 10,
      form: {
        id: null,
        companyId: null,
      },
      formLabelWidth: "100px",
    };
  },
  mounted() {
    this.getShipAgentList();
    this.init();
  },
  methods: {
    List,
    init() {
      this.resetUserList();
      this.resetCompanyList();
      // const date_now = moment().format("YYYY-MM-DD");
      // this.dateRange = [moment(date_now).subtract(14, "days").format("YYYY-MM-DD"), date_now];
      // this.setDate(this.dateRange);
    },
    resetUserList() {
      this.userList = [];
      this.search.showUserId = "";
    },
    resetCompanyList() {
      this.companyList = [];
      this.companysOptions = [];
      this.search.showCompanyId = "";
    },
    getVesselByKeyword(keywords) {
      if (keywords.length < 2) {
        return;
      }
      getVesselByKeyword({keywords: keywords}).then((res) => {
        this.vesselOptions = res.data.data.map((x) => {
          return {
            id: x.id,
            label: x.vesselsName + "/" + x.voyage,
            value: x.id,
          };
        });
      });
    },
    getCompanyUsers(id) {
      this.resetUserList();
      if (!id) {
        this.resetCompanyList();
        return;
      }
      getAllCompanyStaffById({
        id: id,
      })
          .then((res) => {
            if (res.data.data.users) {
              this.userList = res.data.data.users.map((item) => {
                return {
                  id: item.id,
                  label: item.userName,
                  value: item.id,
                };
              });
            }
            const item = {
              id: "",
              label: "全部用户",
              value: null,
            };
            this.userList.unshift(item);
          })
          .catch((err) => {
            console.error(err);
          });
    },
    //获取所有企业
    getAllCompany(name) {
      if (name.trim().length < 2) {
        return;
      }
      const data = {companyName: name.trim()};
      getAllCompany(data).then(({data}) => {
        this.companyList = data.page.list.map((x) => {
          return {
            id: x.id,
            label: x.cname,
            value: x.id,
          };
        });
        this.companysOptions = this.companyList;
      });
      // let data = {
      //   id: this.$store.state.user.userId,
      //   page: this.companyCurrPage,
      //   limit: this.companyTotalPage,
      // }
    },
    //下拉框滚动加载
    loadMore() {
      return () => (this.rangeNumber += 10);
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    //过滤企业数据
    filterCompany(query) {
      if (query) {
        this.companysOptions = this.companyList.filter((x) => {
          return x.label.includes(query);
        });
      } else {
        this.companysOptions = this.companyList;
      }
    },
    searchBills(bills) {
      this.search.billno = bills;
      this.go2Search();
    },
    go2Search() {
      this.$emit("search", this.search);
    },
    //下载舱单模板
    toDownload1() {
      downloadExcelModule1().then((res) => {
        if (res.status === 200) {
          this.saveFile(res.data, `上海舱单列表导入模板.xlsx`);
        }
      });
    },
    //下载舱单模板
    toDownload2() {
      downloadExcelModule2().then((res) => {
        if (res.status === 200) {
          this.saveFile(res.data, `上海舱单单票导入模板.xlsx`);
        }
      });
    },
    //导出模版数据
    exportExcel() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条舱单记录！",
        });
      } else if (this.multipleSelection.length === 1) {
        this.exportLoading = true;
        exportShipExcel(this.multipleSelection[0].id)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(
                    response.data,
                    `${
                        this.multipleSelection[0].billno
                    }${moment().format("YYYYMMDDHHmmss")}舱单.xlsx`,
                );
              }
            })
            .finally(() => {
              this.exportLoading = false;
            });
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条舱单记录！",
        });
      }
    },
    //导出发送记录列表
    exportHistoryExcel() {
      if (this.multipleSelection.length > 0) {
        const ids = this.multipleSelection.map((item) => item.id);
        this.exportLoading = true;
        exportManifestHistoryExcel(ids)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, `已发送舱单列表.xlsx`);
              }
            })
            .finally(() => {
              this.exportLoading = false;
            });
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条舱单记录！",
        });
      }
    },
    //保存文件
    saveFile(data, fileName) {
      let blob = new Blob([data], {
        type: "application/vnd.ms-excel;charset=utf-8",
      }); // res就是接口返回的文件流了
      let url = window.URL || window.webkitURL;
      let fileURL = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = fileName;
      a.target = "_self";
      a.click();
      url.revokeObjectURL(fileURL);
    },
    openManifestDialog() {
      //打开舱单编辑或新建仓单
      this.$emit("edit");
    },
    //上传模板导入舱单
    fileUpload1(file) {
      let filename = file.name;
      let fileSuffix = filename.substr(filename.lastIndexOf(".") + 1);
      if (fileSuffix !== "xlsx") {
        this.$message({
          type: "warning",
          message: "只允许上传xlsx文件！",
        });
        return false;
      }
      importExcelModule1({
        file: file.raw,
        id: this.$store.state.user.userId,
      })
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "导入成功，数据已保存在草稿箱！",
              });
              this.exportDialogVisible1 = false;
              this.$emit("search", this.search);
            } else {
              this.$message.error(response.data.message);
            }
          })
          .finally(() => {
            this.fileList1 = [];
          });
    },
    fileUpload2(file) {
      let filename = file.name;
      let fileSuffix = filename.substr(filename.lastIndexOf(".") + 1);
      if (fileSuffix !== "xlsx") {
        this.$message({
          type: "warning",
          message: "只允许上传xlsx文件！",
        });
        return false;
      }
      importExcelModule2({
        file: file.raw,
        id: this.$store.state.user.userId,
      })
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "导入成功，数据已保存在草稿箱！",
              });
              this.exportDialogVisible2 = false;
              this.$emit("search", this.search);
            } else {
              this.$message.error(response.data.message);
            }
          })
          .finally(() => {
            this.fileList2 = [];
          });
    },
    setDate(val) {
      if (val) {
        console.log(val);
        this.search.startDate = val[0] + " 00:00:00";
        this.search.endDate = val[1] + " 23:59:59";
      } else {
        this.search.startDate = "";
        this.search.endDate = "";
      }
    },
    //发送舱单
    sendManifest() {
      if (this.multipleSelection.length > 0) {
        const data = [];
        this.multipleSelection.forEach((item) => {
          data.push(item.id);
        });
        this.sendLoading = true;
        sendHyManifestShHead(data)
            .then((response) => {
              if (response.data.status) {
                this.$message({
                  type: "success",
                  message: "发送成功",
                });
                getUserAccountInfo();
                this.$emit("search", this.search);
              }
            })
            .finally(() => {
              this.sendLoading = false;
            });
      } else {
        this.$message({
          type: "error",
          message: "请选择要发送的舱单！",
        });
      }
    },
    //修改舱单
    toModify() {
      if (this.multipleSelection.length > 0) {
        if (this.multipleSelection.length > 1) {
          this.$message({
            type: "warning",
            message: "请选择一条舱单！",
          });
          return false;
        }
        this.$emit("edit", this.multipleSelection[0]);
      } else {
        this.$message({
          type: "error",
          message: "请选择要修改的舱单！",
        });
      }
    },
    //删除舱单
    toDelete(type = 0) {
      if (this.multipleSelection.length > 0) {
        // if (type === 1 && this.multipleSelection.length > 1) {
        //   this.$message({
        //     type: "warning",
        //     message: "请选择一条舱单！"
        //   })
        //   return false
        // }
        const deleteData = [];
        this.multipleSelection.forEach((item) => {
          deleteData.push(item);
        });
        this.$nextTick(() => {
          this.$refs.AgentDeleteManifestDialog.init(deleteData, type);
        });
      } else {
        this.$message({
          type: "error",
          message: "请选择要删除的舱单！",
        });
      }
    },

    //设置舱单回执
    setReceiptStatus(type = 0) {
      if (this.multipleSelection.length > 0) {
        // if (type === 1 && this.multipleSelection.length > 1) {
        //   this.$message({
        //     type: "warning",
        //     message: "请选择一条舱单！"
        //   })
        //   return false
        // }
        const list = [];
        this.multipleSelection.forEach((item) => {
          list.push(item.id);
        });

        let setting = (type === "-2" ? billIsDelete : type === "3" ? billIsUpdate : type === "2" ? billIsSend : type === "1" ? resetIsSend : resetBillSendStatus);
        this.deleteLoading = true;
        setting(list)
            .then((res) => {
              if (res.data.status) {
                this.$message.success("设置成功");
                this.$emit("refresh");
              } else {
                this.$message.error(res.data.message);
              }

            })
            .finally(() => {
              this.deleteLoading = false;
            });
      } else {
        this.$message({
          type: "error",
          message: "请选择要操作的舱单！",
        });
      }
    },

    getShipAgentList() {
      getShipAgentList().then(({data}) => {
        this.shipAgent = data.data.map((x) => {
          return {
            id: x.id,
            label: x.name,
            value: x.code,
          };
        });
      });
    },
    showErrorList(data) {
      console.log("showErrorList==", data);
      this.$nextTick(() => {
        this.$refs.ShowManifestErrorDialog.init(data);
      });
    },
    changCompany() {
      if (this.multipleSelection.length === 1) {
        this.selectedItem = this.multipleSelection[0];
        this.dialogFormVisible = true;
      } else {
        this.$message({
          type: "error",
          message: "请选择一条舱单！",
        });
      }
    },
    sumbitCompany() {
      this.form.id = this.selectedItem.id;
      changCompanyResquest(this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "修改成功",
              });
            }
          })
          .finally(() => {
            this.dialogFormVisible = false;
            this.form.companyId = null;
          });
    },
    formatBills(val) {
      this.search.billno = val
          .trim()
          .replace(" ", ",")
          .replace(/[^a-zA-Z0-9,，]/g, "")
          .toUpperCase();
    },
    showMultiBillDialog() {
      this.$refs.MultiBillNoDialog.init(this.search.billno);
    },
  },
  //自定义指令
  directives: {
    "el-select-loadmore": (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap",
      );
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.input_div {
  //padding:1rem;
  margin: 1rem 0;
  //border-top: 1px solid var(--GRAY-f1f3f6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--GRAY-f1f3f6);

  .inline-input {
    margin-right: 1rem;
    width: 20rem;
  }
}

.buttons_div {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el_select {
  margin-right: 1rem;
  width: 12rem;
  display: block;
}

.export_module {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 2rem;
  //border: 2px dashed var(--GRAY-dcdcdc);
  //border-radius: 4px;
}

.el-date-editor--daterange.el-input__inner {
  width: 240px;
}

.blue-text {
  color: blue;
  font-size: 18px;
}

.spaced {
  color: black;
  margin-bottom: 15px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 8px;
}

.form-inline .el-form-item__content {
  display: flex;
  align-items: center;
}

.bill_search_div {
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background: white;
  padding: 0 6px;
  box-sizing: border-box;
  margin-right: 1rem;

  .inline-input {
    margin: 0;
    width: 18rem;
  }

  /deep/ .el-input__inner {
    height: 34px;
    border: none !important;
    padding: 0 30px 0 0 !important;
  }
}


.multi_bill_div {
  cursor: pointer;
  font-size: 12px;
  color: var(--BLUE-409eff);
  padding: 2px;
  box-sizing: border-box;
  margin: 4px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  border-image-source: radial-gradient(closest-side at 50% 50%, transparent 10px, transparent 100%, var(--BLUE-409eff) 50%);
  border-image-slice: 1;
  border-width: 1px;
  border-style: solid;
}
</style>
